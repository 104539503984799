import { createContext, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, Card, Form, Input, notification, Spin } from "antd";
import { CalendarOutlined, ClockCircleOutlined, SmileOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import Payment from "./Payment";
const Context = createContext({ name: 'Default' });
function ShareTeaTime() {
  const [spinning, setSpinning] = useState(false);
  const [isAccepted, setAccepted] = useState(true);
  const { accepted, id } = useParams()
  const [showPayment, setShowPayment] = useState(false);
  const [searchParams] = useSearchParams();
  const [inviteId, setInviteId] = useState(searchParams.get("inviteId" || ""));
  const [booking, setBooking] = useState(null);
  const [amount, setAmount] = useState(0);
  const [invite, setInvite] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  
  const openNotification = (message) => {
    api.open({
      message: 'Invited',
      description: message,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  useEffect(() => {
    if(inviteId) {
      fetchInviteData();
    }
  }, [inviteId]);
  
  const acceptInvite = ()=>{
    setAccepted(true)
  }
  
  const fetchData = async (tries=1)=>{
    try {
      const response = await fetch(`https://us-central1-loopgolfnowapi.cloudfunctions.net/api/get-tee-time-booking?id=${id}`);
      const data = await response.json();
      setBooking(data.booking)
      if (isNaN(data.booking?.price)) {
        const num = Math.ceil((Number.parseFloat(data.booking?.price.replace('$', '')) / 3) * 100);
        setAmount(num);
      } else {
        const num = Math.ceil(Number.parseFloat(data.booking?.price) * 100);
        setAmount(num);
      }
      if (accepted) {
        setShowPayment(true)
      }
      return data;
    }catch (e) {
       if(tries< 5){
         setTimeout(()=>{
           fetchData(tries+1);
         }, 1500)
       }
    }
  }
  
  const fetchInviteData = async ()=>{
    const response = await fetch(`https://us-central1-loopgolfnowapi.cloudfunctions.net/api/get-tee-time-booking-invite?id=${inviteId}`);
    const data = await response.json();
    setInvite(data.invite)
    return data;
  }
  
  const onFinish = (values) => {
    console.log('Success:', values);
    setSpinning(true)
    fetch('https://us-central1-loopgolfnowapi.cloudfunctions.net/api/accept-tea-time-invite',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": values.email,
          "name": values.name,
          "phone": values.phone,
          "note": values.note || "",
          "id": id
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('data', data)
        /* setSpinning(false)
         setInviteId(data.inviteId)
         setShowPayment(true)*/
        if(!data.error) {
          api.success({
            message: 'Tee Time Joined',
            description: 'You have successfully Joined the tee time.'
          });
          setTimeout(() => {
            console.log('data2', data)
            window.location.href = `${window.location.origin}/share/${id}/accepted?inviteId=${data.inviteId}`
          }, 5000)
        }else {
          setSpinning(false)
          api.error({
            message: 'Failed to accept invite',
            description: data.message
          });
        }
      })
      .catch(error => {
        console.error(error)
        setSpinning(false)
        api.error({
          message: 'Failed to accept invite',
          description: error
        });
      });
  };
  
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  console.log('booking', booking)
  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="App" style={{ margin: 'auto', paddingTop: 100 }}>
        <div style={{ margin: 'auto' }}>
          <Card
            style={{
              margin:'auto',
              alignItems: 'center',
              backgroundColor: '#EBECED',
              borderRadius: 10,
              borderWidth: 0
            }}
            className="card-width"
            title={booking?.courseName}
          >
            <div
              style={{ alignItems: 'center' }}>
              <div
                style={{
                  color: 'grey',
                  fontSize: 12,
                  marginBottom: 10,
                }}>{`Your Invitation . ${(booking?.inviteCount || 0)} of ${booking?.groupSize || booking?.timeslot?.slots} accepted`}
              </div>
            </div>
            <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
              <div style={{ width: '33%', alignItems: 'center' }}>
                <UserOutlined/>
                <div style={{}}>{`${booking?.groupSize || booking?.timeslot?.slots || '-'} Players`}</div>
              </div>
              <div style={{ width: '33%', alignItems: 'center' }}>
                <CalendarOutlined/>
                <div style={{}}>{moment(booking?.date).format('ddd, MMM DD')}</div>
              </div>
              <div style={{ width: '33%', alignItems: 'center' }}>
                <ClockCircleOutlined/>
                <div style={{}}>{booking?.time}</div>
              </div>
            </div>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 60,
              marginBottom: 20,
            }}>
              {/*{!accepted && !isAccepted &&<div style={{ alignItems: 'center', width: '45%', display: 'flex', flexDirection: 'column' }}>
                <Button
                  onClick={()=>{openNotification('Invitation is declined')}}
                  danger>Decline</Button>
              </div>}*/}
              <div style={{ alignItems: 'center', width: accepted || isAccepted ?  '100%':'45%', display: 'flex', flexDirection: 'column' }}>
                {!isAccepted && <Button
                  onClick={()=>acceptInvite()}
                  type="primary" style={{backgroundColor: '#B1EC14', color:'#000'}} >{accepted ? 'Accepted':'Accept'}</Button>}
              </div>
            </div>
            
            {<div>
              {(!accepted && isAccepted) &&
                <Form
                  name="basic"
                  labelCol={{ span: 4 }}
                  wrapperCol={{
                    span: 20,
                  }}
                  style={{
                    maxWidth: 500,
                    margin: 'auto',
                    padding: 20
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Full Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Name!',
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input valid Email!',
                        validator: (_, value) => {
                          if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Invalid email address');
                          }
                        }
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  
                  <Form.Item
                    label="phone"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone!',
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item
                    label="Note"
                    name="note"
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your note',
                      },
                    ]}
                  >
                    <Input.TextArea/>
                  </Form.Item>
                  
                  <Button type="primary" htmlType="submit" block style={{ width: '50%', marginTop: 10, height: 40 }}>
                    Accept & Submit
                  </Button>
                </Form>
              }
            </div>}
            
            {<div>
              {showPayment &&
                <Payment
                  resId={booking.resId}
                  phone={booking.phone}
                  amount={amount}
                />}
            </div>}
          
          
          </Card>
        </div>
        <Spin spinning={spinning} fullscreen/>
      </div>
    </Context.Provider>
  );
}
export default ShareTeaTime;
